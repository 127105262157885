import React, { Component } from 'react';
import '../App.css';

import CustomModal from  '../components/Modal';
import BASE_URL from '../baseUrl';

import axios from 'axios';
import { Input, Spin } from 'antd'
import Select from 'react-select';

import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VList from 'react-virtualized/dist/commonjs/List';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';

import CurrencyInput from 'react-currency-input-field';

import { connect } from 'react-redux'
import * as actions from '../store/actions/auth'

const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
// const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

const { Search } = Input

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      games: [],
      tags: [],
      modal: false,
      activeItem: { game: '', user: 1, target_price: "", purchased: false, },
      loading: false,
      hasMore: true,
      next: `${BASE_URL}/api/search/?q=&page=2`,
      search: "",
      prev: "",
      modalerror: false,
      advSearchActive: false,
      searchParams: [],

      title: "",
      tag: "",

      discount: { value: 0, label: 0, },
      discountOperator: { value: ">", label: ">", },

      price: "",
      priceOperator: { value: "<", label: "<", },

      score: { value: 95, label: 95, },
      scoreOperator: { value: ">", label: ">", },

      volume: 0,
      volumeOperator: { value: ">", label: ">", },

    }
    this.windowRef = React.createRef();
  }



  loadedRowsMap = {}

  componentDidMount() {
    window.scrollTo(0,0)
    axios
      .get(`${BASE_URL}/api/search/?q=`)
      .then( res => { this.setState({ 
        games: res.data.results.filter(game => !game.users.includes(+localStorage.getItem('userId'))),
        activeItem: {
          ...this.state.activeItem,
          user: (localStorage.getItem('userId') === undefined ? 1 : +localStorage.getItem('userId')),
        },

      }) } )
      // .catch( err => console.log(err) )
    axios
      .get(`${BASE_URL}/api/tags/`)
      .then( res => { 
        this.setState({ 
          tags: [
            ...res.data.results.map(e => ({value: e, label: e}) ), 
            {value: "", label: "Select..."}
          ] 
        }) 
        console.log(res.data.results)
      })
    this.timer = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.advSearchActive !== prevState.advSearchActive) {
      console.log("Position updated")
      this.windowRef.updatePosition();
    }
  }

  handleInfiniteLoad = ({ startIndex, stopIndex }) => {
    console.log("handleInfiniteLoad, triggered")
    let { next, games } = this.state 
    this.setState({ loading: true })

    for (let i = startIndex; i <= stopIndex; i++) { this.loadedRowsMap[i] = 1 } // 1: loading
    if (!next) { this.setState({ hasMore: false, loading: false}); return; }

    axios.get(next)
      .then(res => {
        let updated_games = [...games, ...res.data.results].filter(
          game => !game.users.includes(+localStorage.getItem('userId'))
        )
        console.log("handleInfiniteLoad::updated_games", updated_games)
        this.setState({ games: updated_games, next: res.data.next })
      })
      .then(res => {
        this.setState({ loading: false})
      })
  }

  isRowLoaded = ({ index }) => !!this.loadedRowsMap[index]

  toggle = (app_id) => { 
    let new_id = !this.state.modal ? app_id : ''
    this.setState({ 
      modal: !this.state.modal, 
      activeItem: { ...this.state.activeItem, game: new_id },
      modalerror: false,
    }) 
  }

  handleSubmit = (e, item) => {
    item = {
      ...item,
      target_price: parseFloat(item.target_price)
    }
    e.preventDefault()
    if (item.target_price >= 0) {
      axios.defaults.headers = {
        "Content-Type": "application/json",
        "Authorization": `Token ${this.props.token}`
      }
      axios
        .post(`${BASE_URL}/api/wishlistitems/`, item)
      this.setState({ games: this.state.games.filter(game => game.id !== item.game) })
      this.toggle()
    } else {
      this.setState({ modalerror: true })
    }
  }

  dynamicSearch = (value, page=1) => {
    this.loadedRowsMap = {}
    let url = `${BASE_URL}/api/search/?${value}&page=${page}`
    // let url = !this.state.advSearchActive ? 
    //   `${BASE_URL}/api/search/?${value}&page=${page}`
    //     :
    //   `${BASE_URL}/api/search?${this.state.searchParams.join("&")}&page=${page}`
    axios.get(url)
      .then(res => {
        let { results, next } = res.data
        let updated_games = results.filter(
          game => !game.users.includes(+localStorage.getItem('userId'))
        )
        updated_games = +page > 1 ? [...this.state.games, ...updated_games] : [...updated_games]
        console.log("dynamicSearch::updated_games", updated_games)
        this.setState({
          games: updated_games,
          next: next,
          search: this.state.advSearchActive ? "" : value.split("q=")[1],
          prev: this.state.search,
        })
        if (updated_games.length < 30 && next) { 
          console.log("TOO SHORT: trigger updated games")
          // page = next.split("?")[1].split("&")[0].split("=")[1]
          page = +(next.split("page=")[1].split("&")[0])
          this.dynamicSearch(value, page)
        }
      })
  }

  renderHeaders = (len) => (
    <div className="row" key={len ? {} : { 'display': 'none' }}>
      <div className="offset-lg-1 col-lg-10 col-12">
        <div className='row'>
        <div className='col-lg-5 col-md-4 col-3 game-title title-header table-header text'>
          <strong>Game Title</strong>
        </div>
        <div className={`col-sm-2 col-1 game-price table-header curr-header text`} id='price1'><strong>Current Price</strong></div>
        <div className={`col-2 game-price table-header base-header text`} id='price2'><strong>Base Price</strong></div>
        </div>
      </div>
    </div>
  )

  renderItem = ({ style, index, key }) => {

    let btn    = "col-sm-0 btn btn-success btn-pad"
    let imgSrc = (id) => `https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/${id}/header.jpg`
    // let imgSrc = (id) => `https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/${id}/capsule_231x87.jpg`

    const { games } = this.state
    const game      = games[index]

    let gameTitle = (vw > 500 || game.title.length <= 35) ? game.title : 
      game.title.slice(0,35).split(" ").slice(0, -1).join(" ")+"..."

    let steamUrl = `https://store.steampowered.com/app/${game.steam_id}/`

    return (
      <div style={style} key={key}>
        <div className="row game" key={key}>
          <button className={btn} id={game.id} onClick={() => this.toggle(game.id) }>
            <div className='plus'>+</div>
          </button>
          <img className='game-img' alt={game.title} src={imgSrc(game.steam_id)} />
          <div className={`col-lg-5 col-md-4 col-3 game-title text`}>
            <a href={steamUrl}>{gameTitle}</a>
          </div>
          <div className={`col-sm-2 col-1 game-price text`} id='price1'>
            ${game.curr_price}
          </div>
          <div className={`col-2 game-price text`} id='price2'>
            ${game.base_price}
          </div>
        </div>
        <div className='row divider'></div>
      </div>
    )
  }

  render() {
    let tableHeaders = (
      <div className="row">
        <div className="offset-lg-1 col-lg-10 col-12">
          <div className='row'>
          <div className='col-lg-5 col-md-4 col-3 game-title title-header table-header text'>
            <strong>Game Title</strong>
          </div>
          <div className={`col-sm-2 col-1 game-price table-header curr-header text`} id='price1'><strong>Current Price</strong></div>
          <div className={`col-2 game-price table-header base-header text`} id='price2'><strong>Base Price</strong></div>
          </div>
        </div>
      </div>
    )

    const firstHundred = [...new Array(101)].map((e,i) => (i))

    const firstHundredOptions = [...firstHundred.map(e => ({ value: e, label: e}) )]

    const operators = [
      { value: ">", label: ">", },
      { value: "<", label: "<", },
      { value: "=", label: "=", },
    ]

    const { games } = this.state;
    const vlist = ({ height, isScrolling, onChildScroll, scrollTop, onRowsRendered, width }) => (
      <VList
        height={height} 
        isScrolling={isScrolling} 
        onScroll={onChildScroll}
        scrollTop={scrollTop} 
        onRowsRendered={onRowsRendered} 
        width={width}

        // searchTerm={this.state.searchParams}
        // searchCleared={!this.state.search && !!this.state.prev}

        autoHeight
        rowHeight={90}
        overscanRowCount={5}
        rowCount={games.length}
        rowRenderer={this.renderItem}
      />
    );


    const autoSize = ({ height, isScrolling, onChildScroll, scrollTop, onRowsRendered }) => (
      <AutoSizer 
        disableHeight 
      >
        {({ width }) => vlist({ height, isScrolling, onChildScroll, scrollTop, onRowsRendered, width }) }
      </AutoSizer>
    );
    const infiniteLoader = ({ height, isScrolling, onChildScroll, scrollTop }) => (
      <InfiniteLoader isRowLoaded={this.isRowLoaded} loadMoreRows={this.handleInfiniteLoad} rowCount={games.length}>
        { ({ onRowsRendered }) => autoSize({ height, isScrolling, onChildScroll, scrollTop, onRowsRendered }) }
      </InfiniteLoader>
    )

    return (
      <div className="App background">
        <h1>Add Steam Games to Wishlist</h1>
        <div className="container"
          // id='gamelist'
        >
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-12 title-search" >
            { !this.state.advSearchActive && (
              <Search 
                className="searchbar"
                list="games" 
                placeholder="Quick search: type to filter by title..." 
                onChange = { e => {
                    let { value } = e.target
                    clearTimeout(this.timer)
                    this.timer = setTimeout(() => this.dynamicSearch(`q=${value}`), 400)
                  }
                }
                enterButton
              />
              )
            }
            </div>
            <div className="offset-lg-2 col-lg-8 col-12 mb-3">
              <div className="btn-container">
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.setState({
                    advSearchActive: !this.state.advSearchActive
                  })
                }} 
              >
                { this.state.advSearchActive ? "Hide Advanced Filters" : "Advanced Search Filters" }
              </button>
              </div>
            {
              this.state.advSearchActive && (
              <div>
                <h1 className="mt-3" style={{color: "white", textAlign: "center"}}>Advanced Search</h1>

                <div className="dropdown-form">
                  <span className="label">
                    Title
                  </span>
                  <input
                    value={this.state.title}
                    onChange={(e) => { this.setState({ title: e.target.value }) }}
                    className="title-input"
                  />
                  <button
                    className="btn btn-outline-primary ml-3"
                    onClick={() => {
                      this.setState({
                        searchParams: [
                          ...this.state.searchParams,
                          `q=${this.state.title}`,
                        ],
                        title: "",
                      })
                    }}
                  >
                    Add to Search
                  </button>
                </div>

                <div className="dropdown-form mt-3">
                  <span className="label">
                    Tag
                  </span>
                  <Select 
                    className="dropdown-lg"
                    options={this.state.tags}
                    value={this.state.tag.value}
                    onChange={(e) => { this.setState({ tag: e.value }) }}
                    isSearchable
                  />
                  <button
                    className="btn btn-outline-primary ml-3"
                    onClick={() => {
                      this.setState({
                        searchParams: [
                          ...this.state.searchParams,
                          `tag=${this.state.tag}`,
                        ],
                        tag: "",
                      })
                    }}
                  >
                    Add to Search
                  </button>
                </div>

                <div className="dropdown-form mt-3">
                  <span className="label">
                    Discount
                  </span>
                  <Select
                    className="operator-dropdown mr-3"
                    options={operators}
                    value={this.state.discountOperator}
                    onChange={(e) => { 
                      this.setState({ discountOperator: e }); 
                    }}
                    isSearchable
                  />
                  <Select 
                    className="dropdown"
                    options={[...firstHundredOptions]}
                    value={this.state.discount}
                    onChange={(e) => { this.setState({ discount: e }) }}
                    isSearchable
                  />
                  <button
                    className="btn btn-outline-primary ml-3"
                    onClick={() => {
                      this.setState({
                        searchParams: [
                          ...this.state.searchParams,
                          `discount=${this.state.discountOperator.value}${this.state.discount.value}`,
                        ],
                        discount: 0,
                        discountOperator: { value: ">", label: ">", },
                      })
                    }}
                  >
                    Add to Search
                  </button>
                </div>

                <div className="dropdown-form mt-3">
                  <span className="label">
                    Curr Price
                  </span>
                  <Select
                    className="operator-dropdown mr-3"
                    value={this.state.priceOperator}
                    onChange={(e) => { this.setState({ priceOperator: e }) }}
                    options={operators}
                    isSearchable
                  />

                  <CurrencyInput 
                    className="search-input"
                    prefix={"$"}
                    step="0.01"
                    value={this.state.price}
                    onValueChange={(value, name, values) => {
                      this.setState({
                        price: value,
                      })
                    }}
                    disableAbbreviations
                    decimalScale={2}
                  />
                  <button
                    className="btn btn-outline-primary ml-3"
                    onClick={() => {
                      this.setState({
                        searchParams: [
                          ...this.state.searchParams,
                          `price=${this.state.priceOperator.value}${this.state.price}`,
                        ],
                        price: "",
                        priceOperator: { value: ">", label: ">", },
                      })
                    }}
                  >
                    Add to Search
                  </button>
                </div>

                <div className="dropdown-form mt-3">
                  <span className="label">
                    Positive Review %
                  </span>
                  <Select
                    className="operator-dropdown mr-3"
                    defaultValue={operators[0]}
                    options={operators}
                    onChange={ (e) => { this.setState({ scoreOperator: e }) } }
                    isSearchable
                  />
                  <Select 
                    className="dropdown"
                    options={[...firstHundredOptions].reverse()}
                    value={this.state.score}
                    onChange={ (e) => { this.setState({ score: e }) } }
                    isSearchable
                  />
                  <button
                    className="btn btn-outline-primary ml-3"
                    onClick={() => {
                      this.setState({
                        searchParams: [
                          ...this.state.searchParams,
                          `score=${this.state.scoreOperator.value}${this.state.score.value}`,
                        ],
                        score: { value: 95, label: 95 },
                        volumeOperator: { value: ">", label: ">", },
                      })
                    }}
                  >
                    Add to Search
                  </button>
                </div>

                <div className="dropdown-form mt-3">
                  <span className="label">
                    # of Reviews 
                  </span>
                  <Select
                    className="operator-dropdown mr-3"
                    options={operators}
                    value={this.state.volumeOperator}
                    onChange={ (e) => { this.setState({ volumeOperator: e }) } }
                    isSearchable
                  />
                  <CurrencyInput 
                    className="search-input"
                    allowDecimals={false}
                    value={this.state.volume}
                    onValueChange={(value, name, values) => {
                      this.setState({
                        volume: value,
                      })
                    }}
                  />
                  <button
                    className="btn btn-outline-primary ml-3"
                    onClick={() => {
                      this.setState({
                        searchParams: [
                          ...this.state.searchParams,
                          `reviews=${this.state.volumeOperator.value}${this.state.volume}`,
                        ],
                        volume: 0,
                        volumeOperator: { value: ">", label: ">", },
                      })
                    }}
                  >
                    Add to Search
                  </button>
                </div>
                <div>
                  {
                    this.state.searchParams && (
                      <div>
                        {
                          this.state.searchParams.map((e, i) => (
                            <button
                              key={`search-key-${i}`}
                              className="btn btn-outline-light text"
                              onClick={() => {
                                this.setState({
                                  searchParams: [
                                    ...this.state.searchParams.slice(0,i),
                                    ...this.state.searchParams.slice(i+1),
                                  ]
                                })
                              }}
                            >
                              {e}
                            </button>
                          ))
                        }
                      </div>
                    )
                  }
                  <div className="btn-container mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => { this.dynamicSearch(this.state.searchParams.join("&")) }}
                  >
                    Search
                  </button>
                  </div>
                </div>


              </div>
              )
            }
            </div>
          </div>
          { games.length && tableHeaders }
          {/* { this.renderHeaders(this.state.games)} */}
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-12" id='gamelist'>
              {games.length > 0 && <WindowScroller ref={this._setRef}>{infiniteLoader}</WindowScroller>}
              {/* {games.length > 0 && infiniteLoader} */}
              {games.length === 0 && 
              <div className='row game blank-search text'>
                No results match your search.
              </div>  
              }
            </div>
          </div>
        </div>
        { this.state.modal ? (
          <CustomModal 
            activeItem={this.state.activeItem} 
            toggle={this.toggle} 
            onSave={this.handleSubmit} 
            error={this.state.modalerror}
            is_unauthenticated={!this.props.token}
          />
        ) : null }
        { this.state.loading && this.state.hasMore && (<div><Spin /></div>)}
      </div>
    );
  }

  _setRef = (ref) => {
    console.log("setting reference")
    this.windowRef = ref;
  }
}

const mapStateToProps = (state) => {
    return {
        userId: state.auth.userId,
        token: state.auth.token,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(actions.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
